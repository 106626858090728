import get from "lodash.get";
import { authHealthCheck } from "@/services/auth.service";
import { fileServiceHealthCheck } from "@/services/file.service";
import { cashMngtHealthCheck } from "@/services/cashManagement.service";
import { manpowerHealthCheck } from "@/services/manpower.service";
import { inventoryHealthCheck } from "@/services/inventory.service";
import { notificationsHealthCheck } from "@/services/notification.service";
import { touristHealthCheck } from "@/services/tourist.service";
import { opsCommHealthCheck } from "@/services/opsComms.service";
import { shiftHandoverHealthCheck } from "@/services/shiftHandover.service";
import { faultManagementHealthCheck } from "@/services/faultManagement.service";

const state = () => ({
  isOnline: {
    authapi: true,
    manpowerapi: true,
    cashmanagementapi: true,
    inventoryapi: true,
    faultmanagementapi: true,
    opscommapi: true,
    notificationapi: true,
    touristapi: true,
    shifthandoverapi: true,
    fileserviceapi: true,
  },
  checkingStatus: null,
  connectionStatusError: null,
});

const getters = {
  isAllApiOnline: (state) => Object.values(state.isOnline).every(onlineStatus => onlineStatus),
  isCMModuleOnline: (state) => (
    state.isOnline.authapi &&
    state.isOnline.manpowerapi &&
    state.isOnline.cashmanagementapi &&
    state.isOnline.fileserviceapi
  ),
  isInventoryModuleOnline: (state) => (
    state.isOnline.authapi &&
    state.isOnline.manpowerapi &&
    state.isOnline.inventoryapi &&
    state.isOnline.fileserviceapi
  ),
  apiCheckingStatus: (state) => get(state, "checkingStatus", null),
  apiStatusError: (state) => get(state, "connectionStatusError", null),
};

const mutations = {
  setAuthApiStatus(state, onlineStatus) {
    state.isOnline.authapi = onlineStatus;
  },
  setManpowerApiStatus(state, onlineStatus) {
    state.isOnline.manpowerapi = onlineStatus;
  },
  setCMApiStatus(state, onlineStatus) {
    state.isOnline.cashmanagementapi = onlineStatus;
  },
  setInventoryApiStatus(state, onlineStatus) {
    state.isOnline.inventoryapi = onlineStatus;
  },
  setFaultMngtApiStatus(state, onlineStatus) {
    state.isOnline.faultmanagementapi = onlineStatus;
  },
  setOpsCommApiStatus(state, onlineStatus) {
    state.isOnline.opscommapi = onlineStatus;
  },
  setTouristApiStatus(state, onlineStatus) {
    state.isOnline.touristapi = onlineStatus;
  },
  setFileServiceApiStatus(state, onlineStatus) {
    state.isOnline.fileserviceapi = onlineStatus;
  },
  setNotificationApiStatus(state, onlineStatus) {
    state.isOnline.notificationapi = onlineStatus;
  },
  setShiftHandoverApiStatus(state, onlineStatus) {
    state.isOnline.shifthandoverapi = onlineStatus;
  },
  setIsStatusChecking(state, checkingStatus) {
    state.checkingStatus = checkingStatus;
  },
  setStatusError(state, connectionStatusError) {
    state.connectionStatusError = connectionStatusError;
  },
  setAllStatus(state, onlineStatus) {
    state.isOnline.authapi = onlineStatus;
    state.isOnline.manpowerapi = onlineStatus;
    state.isOnline.cashmanagementapi = onlineStatus;
    state.isOnline.inventoryapi = onlineStatus;
    state.isOnline.touristapi = onlineStatus;
    state.isOnline.opscommapi = onlineStatus;
    state.isOnline.faultmanagementapi = onlineStatus;
    state.isOnline.shifthandoverapi = onlineStatus;
    state.isOnline.notificationapi = onlineStatus;
    state.isOnline.fileserviceapi = onlineStatus;
  },
  reset(state) {
    state.isOnline.authapi = true;
    state.isOnline.manpowerapi = true;
    state.isOnline.cashmanagementapi = true;
    state.isOnline.inventoryapi = true;
    state.isOnline.touristapi = true;
    state.isOnline.opscommapi = true;
    state.isOnline.faultmanagementapi = true;
    state.isOnline.shifthandoverapi = true;
    state.isOnline.notificationapi = true;
    state.isOnline.fileserviceapi = true;
    state.checkingStatus = null;
    state.connectionStatusError = null;
  },
};

const actions = {
  getConnectionStatus: async function({ commit }) {
    commit("setIsStatusChecking", true);
    
    try {
      const responses = await Promise.all([
        await authHealthCheck(),
        await manpowerHealthCheck(),
        await cashMngtHealthCheck(),
        await inventoryHealthCheck(),
        await touristHealthCheck(),
        await fileServiceHealthCheck(),
        await notificationsHealthCheck(),
        await faultManagementHealthCheck(),
        await opsCommHealthCheck(),
        await shiftHandoverHealthCheck(),
      ]);
      commit('setAuthApiStatus', get(responses[0], 'payload.success', false));
      commit('setManpowerApiStatus', get(responses[1], 'payload.success', false));
      commit('setCMApiStatus', get(responses[2], 'payload.success', false));
      commit('setInventoryApiStatus', get(responses[3], 'payload.success', false));
      commit('setTouristApiStatus', get(responses[4], 'payload.success', false));
      commit('setFileServiceApiStatus', get(responses[5], 'payload.success', false));
      commit('setNotificationApiStatus', get(responses[6], 'payload.success', false));
      commit('setFaultMngtApiStatus', get(responses[7], 'payload.success', false));
      commit('setOpsCommApiStatus', get(responses[8], 'payload.success', false));
      commit('setShiftHandoverApiStatus', get(responses[9], 'payload.success', false));

      const errorResponse = responses.find(response => !response.success);
      if (errorResponse != null) {
        commit('setStatusError', null);
      } else {
        commit('setStatusError', errorResponse);
      }
    } catch (e) {
      commit('setAllStatus', false);
    }
    commit("setIsStatusChecking", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
