import ROLES from "../../Roles.json";

export default [
  {
    path: "/shift-handover",
    name: "Shift Handover Main Page",
    component: () =>
      import("../views/shift-handover/ShiftHandoverDefaultWrapper.vue"),
    meta: {
      title: "screens.shiftHandover.Main.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Shift Handover Main Page Main",
        component: () => import("../views/shift-handover/Main.vue"),
        meta: {
          title: "screens.shiftHandover.Main.pageTitle",
        },
      },
      {
        path: "full-handover",
        name: "Full Shift Handover",
        component: () => import("../views/shift-handover/Create.vue"),
        meta: {
          title: "screens.shiftHandover.Create.pageTitle",
        },
      },
      {
        path: "soft-handover",
        name: "Soft Shift Handover",
        component: () => import("../views/shift-handover/Create.vue"),
        meta: {
          title: "screens.shiftHandover.Create.pageTitle",
        },
      },
      {
        path: "soft-takeover/:shiftHandoverId",
        name: "Soft Shift Takeover",
        component: () => import("../views/shift-handover/Takeover.vue"),
        meta: {
          title: "screens.shiftHandover.Takeover.pageTitle",
        },
      },
      {
        path: "details/:shiftHandoverId",
        name: "Shift Handover Details",
        component: () => import("../views/shift-handover/Details.vue"),
        meta: {
          title: "screens.shiftHandover.Details.pageTitle",
        },
      },
      {
        path: "takeover/:shiftHandoverId",
        name: "Shift Handover Takeover",
        component: () => import("../views/shift-handover/Takeover.vue"),
        meta: {
          title: "screens.shiftHandover.Takeover.pageTitle",
        },
      },
      {
        path: "sm-keys",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.shiftHandover.SMKeys.pageTitle",
        },
        children: [
          {
            path: "",
            name: "SM Keys Main Page",
            component: () =>
              import("../views/shift-handover/stationItems/smKeys/Index.vue"),
            meta: {
              title: "screens.shiftHandover.SMKeys.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
                ROLES.OpsAdmin,
              ],
            },
          },
        ],
      },
      {
        path: "sm-duty-items",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.shiftHandover.SMDutyItems.pageTitle",
        },
        children: [
          {
            path: "",
            name: "SM Duty Items Main Page",
            component: () =>
              import("../views/shift-handover/stationItems/smItems/Index.vue"),
            meta: {
              title: "screens.shiftHandover.SMDutyItems.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
                ROLES.OpsAdmin,
              ],
            },
          },
        ],
      },
      {
        path: "afc-keys",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.shiftHandover.AFCStoreSpareKey.pageTitle",
        },
        children: [
          {
            path: "",
            name: "AFC Store Spare Keys",
            component: () =>
              import("../views/shift-handover/AFCStoreSpareKey.vue"),
            meta: {
              title: "screens.shiftHandover.AFCStoreSpareKey.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
        ],
      },
      {
        path: "declaration",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.shiftHandover.Declaration.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Fitness Observation",
            component: () =>
              import("../views/shift-handover/declaration/Index.vue"),
            meta: {
              title: "screens.shiftHandover.Declaration.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
                ROLES.OpsAdmin,
              ],
            },
          },
        ],
      },
    ],
  },
];
