import get from 'lodash.get'
import { listRoles } from '../services/user.service'
import ROLES from '../../Roles.json';

const state = () => ({
  roles: [],
});

const getters = {
  roleOptions(state) {
    return state.roles.map(({ id, key, label }) => ({
      value: id,
      key,
      label
    }))
  },
  roleKey: (state) => (roleId) => {
    const role = state.roles.find(r => r.id === roleId);
    return get(role, 'key');
  },
  hasSeniorRole: (_, getters) => (roleId) => {
    const roleKey = getters['roleKey'](roleId);
    return roleKey == ROLES.StationManager ||
      roleKey === ROLES.AssistantStationManager;
  },
  seniorRoleId: (state, getters) => (roleId, isSenior) => {
    if (!isSenior)
      return roleId;
    const roleKey = getters['roleKey'](roleId);
    const seniorSM = state.roles.find(r => r.key == ROLES.SeniorStationManager);
    const seniorASM = state.roles.find(r => r.key == ROLES.SeniorAssistantStationManager);
    switch (roleKey) {
      case ROLES.StationManager:
        return get(seniorSM, 'id') || roleId;
        case ROLES.AssistantStationManager:
          return get(seniorASM, 'id') || roleId;
      default:
        return roleId;
    }
  },
  nonSeniorRoleOptions(_, getters) {
    const seniorRoles = [
      ROLES.SeniorStationManager,
      ROLES.SeniorAssistantStationManager,
    ]
    return getters['roleOptions'].filter(role =>
      !seniorRoles.includes(role.key)
    );
  },
  cpUpdateRoleOptions(_, getters) {
    const allowedRoles = [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManagerControlRoom,
    ]
    return getters['roleOptions'].filter(role =>
      allowedRoles.includes(role.key)
    );
  }
}

const mutations = {
  setRoles(state, roles = []) {
    state.roles = roles
  },
  reset(state) {
    state.roles = []
  }
}

const actions = {
  getRoles: async function({ commit }) {
    const result = await listRoles({ skip: 0, take: 100})
    if (result.success) {
      commit('setRoles', get(result, 'payload.list', []))
      return result;
    } else {
      return result;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}