/**
 * Mapping data to GET request params
 * @param data: JSON data of what need to be pass to request
 * @returns URLSearchParams params to pass to axios request
 */
export function getURLSearchParams({
  data = {},
}) {
  let params = new URLSearchParams()
  if (!data) data = {}
  const paramKeys = Object.keys(data)
  for (let i = 0; i < paramKeys.length; i++) {
    const key = paramKeys[i]
    const value = data[key]
    if (value === null || value === undefined)
      continue;
    else {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          params.append(key, v)
        })
      } else {
        params.append(key, value)
      }
    }
  }
  return params
}