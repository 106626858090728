import ROLES from '../../Roles.json'

export default [
  {
    path: '/users',
    component: () => import('../views/DefaultWrapper.vue'),
    meta: {
      title: 'screens.userManagement.Main.pageTitle',
    },
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users_index" */ '../views/users/Index.vue'),
        meta: {
          title: 'screens.userManagement.users.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      },
      {
        path: 'create',
        name: 'CreateUser',
        component: () => import(/* webpackChunkName: "users_create" */ '../views/users/Create.vue'),
        meta: {
          title: 'screens.userManagement.createUser.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      },
      {
        path: 'changeRole/:id',
        name: 'ChangeUserRole',
        component: () => import(/* webpackChunkName: "users_changeRole" */ '../views/users/ChangeRole.vue'),
        meta: {
          title: 'screens.userManagement.changeUserRole.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      },
      {
        path: "UserStations",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.userManagement.UserStations.pageTitle",
          requiresRoles: [  ROLES.SystemAdmin ],
        },
        children: [
          {
            path: "/users/UserStations",
            name: "userStations",
            component: () => import("../views/users/UserStations.vue"),
          },
          {
            path: "/users/AddUserStation",
            name: "addUserStation",
            component: () => import( "../views/users/AddUserStation.vue" ),
            meta: {
              title: "screens.userManagement.AddUserStation.pageTitle",
              requiresRoles: [ ROLES.SystemAdmin ],
            },
          },
          {
            path: "/users/EditUserStation/:userStationQueueId",
            name: "editUserStation",
            component: () => import( "../views/users/EditUserStation.vue" ),
            meta: {
              title: "screens.userManagement.EditUserStation.pageTitle",
              requiresRoles: [ ROLES.SystemAdmin ],
            },
          },
        ],
      },
      {
        path: 'editUserDetails/:id',
        name: 'EditUserDetails',
        component: () => import(/* webpackChunkName: "users_changeStation" */ '../views/users/EditUser.vue'),
        meta: {
          title: 'screens.userManagement.editUserDetails.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      }, 
      {
        path: '/configuration',
        name: 'SystemAdminConfig',
        component: () => import(/* webpackChunkName: "users_changeStation" */ '../views/users/Configuration.vue'),
        meta: {
          title: 'screens.userManagement.config.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      },
      {
        path: '/user-access-logs',
        name: 'UserAccessLog',
        component: () => import(/* webpackChunkName: "users_changeStation" */ '../views/users/UserAccessLog.vue'),
        meta: {
          title: 'screens.userManagement.userAccessLog.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      },
      {
        path: '/user-activation-logs',
        name: 'UserManagementLog',
        component: () => import(/* webpackChunkName: "users_changeStation" */ '../views/users/UserManagementLog.vue'),
        meta: {
          title: 'screens.userManagement.userManagementLog.pageTitle',
          requiresRoles: [ ROLES.SystemAdmin ],
        },
      },
    ],
  }
]