import get from "lodash.get";
import isEmpty from "lodash.isempty";
import find from "lodash.find";
import { getCashOrderList } from "../../services/cashManagement.service";

const state = () => ({
  data: [],
  isLoading: false,
  error: null,
});

const getters = {
  getById: (state) => (id) =>
    state.data.find(({ cashOrderId }) => cashOrderId === id),
  getList: (state) => state.data,
  getListFromContractorAsOptions: (state) =>
    state.data
      .filter(({ cashOrderStatusEnum }) => cashOrderStatusEnum === 3)
      .map(({ cashOrderId, referenceNo }) => ({
        value: cashOrderId,
        label: referenceNo,
      })),
  getListFromOtherStationsAsOptions: (state, _, __, rootGetters) => {
    const afcId = rootGetters["cashManagement/stationCashAccounts/afcId"];
    return state.data
      .filter(
        ({ cashOrderStatusEnum, supplierId }) =>
          cashOrderStatusEnum === 4 && supplierId == afcId
      )
      .map(({ cashOrderId, referenceNo }) => ({
        value: cashOrderId,
        label: referenceNo,
      }));
  },
  getIsLoading: (state) => state.isLoading,
  getError: (state) => state.error,
  getCashOrder: (state) => (referenceNo) =>
    find(state, { referenceNo: referenceNo }),
};

const mutations = {
  setData(state, data = []) {
    state.data = [...data];
  },
  setLoading(state, loading = false) {
    state.isLoading = loading;
  },
  setError(state, error) {
    state.error = isEmpty(error) ? null : { ...error };
  },
  reset(state) {
    state.data = [];
    state.isLoading = false;
    state.error = null;
  },
};

const actions = {
  loadCashOrderList: async ({ commit, rootGetters }) => {
    const afcId = rootGetters["cashManagement/stationCashAccounts/afcId"];
    commit("setError", null);
    commit("setLoading", true);
    const result = await getCashOrderList({ aFCId: afcId });
    if (result.success) {
      commit("setData", get(result, "payload.list", []));
    } else {
      commit("setError", result.payload);
    }

    commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
