import ROLES from "../../../Roles.json";

export default {
  canEditStationFaultExpireThreshold(state) {
    return state.roleKey === ROLES.OpsAdmin;
  },
  canCreateStationFault(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager
    );
  },
  canEditStationFault(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager
    );
  },
  canViewStationFault(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canCreateEBooklets(state) {
    return state.roleKey === ROLES.OpsAdmin;
  },
  canEditEBooklets(state) {
    return state.roleKey === ROLES.OpsAdmin;
  },
  canViewEBooklets(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canCreateStationOccurrence(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canEditStationOccurrence(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canViewStationOccurrence(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
};
