import get from 'lodash.get'
import {
  getCashManagementConfig,
  updateCoinTubeConfiguration,
  updateCutOffTime,
  updateStationCashThreshold,
} from '@/services/cashManagement.service.js'

const state = () => ({
  cutOffTime: "",
  certisBagAmount: 0,
  stationCashThresholds: [],
  coinTubeConfiguration: {
    numberOf10CentsPerTube: 0,
    numberOf20CentsPerTube: 0,
    numberOf50CentsPerTube: 0,
    numberOf1DollarsPerTube: 0,
  },
});

const getters = {
  cutOffTimeText: (state) => state.cutOffTime,
  coinTubeConfiguration: (state) => state.coinTubeConfiguration,
  getThresholdOfStation: (state) => (stationId, lineId) => 
    state.stationCashThresholds.find((threshold) => threshold.stationId == stationId && threshold.lineId == lineId),
};

const mutations = {
  set(state, data) {
    state.cutOffTime = get(data, 'data.cutOffTime', '');
    state.certisBagAmount = get(data, 'data.certisBagAmount', 0);
    state.stationCashThresholds = get(data, 'data.stationCashThresholds', []);
    state.coinTubeConfiguration.numberOf10CentsPerTube = get(data, 'data.coinTubeConfiguration.numberOf10CentsPerTube', 0);
    state.coinTubeConfiguration.numberOf20CentsPerTube = get(data, 'data.coinTubeConfiguration.numberOf20CentsPerTube', 0);
    state.coinTubeConfiguration.numberOf50CentsPerTube = get(data, 'data.coinTubeConfiguration.numberOf50CentsPerTube', 0);
    state.coinTubeConfiguration.numberOf1DollarsPerTube = get(data, 'data.coinTubeConfiguration.numberOf1DollarsPerTube', 0);
  },
  setCutOffTime(state, data) {
    state.cutOffTime = data;
  },
  setStationCashThreshold(state, data) {
    const { stationId, lineId } = data;
    const newStationCashThresholds = state.stationCashThresholds.map(item => {
      if (!(item.stationId == stationId && item.lineId == lineId)) return item;
      return data;
    });
    state.stationCashThresholds = newStationCashThresholds;
  },
  setCoinTubeConfiguration(state, data) {
    state.coinTubeConfiguration.numberOf10CentsPerTube = get(data, 'numberOf10CentsPerTube', 0);
    state.coinTubeConfiguration.numberOf20CentsPerTube = get(data, 'numberOf20CentsPerTube', 0);
    state.coinTubeConfiguration.numberOf50CentsPerTube = get(data, 'numberOf50CentsPerTube', 0);
    state.coinTubeConfiguration.numberOf1DollarsPerTube = get(data, 'numberOf1DollarsPerTube', 0);
  },
  reset(state) {
    state.cutOffTime = "";
    state.stationCashThresholds = [];
    state.coinTubeConfiguration.numberOf10CentsPerTube = 0;
    state.coinTubeConfiguration.numberOf20CentsPerTube = 0;
    state.coinTubeConfiguration.numberOf50CentsPerTube = 0;
    state.coinTubeConfiguration.numberOf1DollarsPerTube = 0;
  }
};

const actions = {
  getDataAsync: async ({ commit }) => {
    const result = await getCashManagementConfig();
    if (result && result.success) 
      commit('set', result.payload)
    return result;
  },
  updateCoinTubeAsync: async (data, { commit }) => {
    const result = await updateCoinTubeConfiguration(data);
    if (result && result.success)
      commit('setCoinTubeConfiguration', data)
    return result;
  },
  updateStationCashThresholdAsync: async (data, { commit }) => {
    const result = await updateStationCashThreshold(data);
    if (result && result.success)
      commit('setStationCashThreshold', data)
    return result;
  },
  updateCutOffTimeAsync: async (data, { commit }) => {
    const result = await updateCutOffTime(data);
    if (result && result.success)
      commit('setCutOffTime', data)
    return result;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

