import {
  LIST_USERS,
  LIST_ROLES,
  ADD_USER,
  EDIT_USER,
  CHANGE_USER_ROLES,
  GET_USER,
  FETCH_USERS,
  ACTIVATE_USER,
  DEACTIVATE_USER,
  ADD_STATION_QUEUE,
  ADD_STATION_DETAIL,
  ADD_STATION_EDIT,
  CHANGE_USER_RESIGN_DATE,
  LIST_USER_STATION_QUEUES,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function listUsers({
  skip = 0,
  take = 100,
  search = "",
  lineId = "",
  stationId = "",
  roleNames,
}) {
  try {
    const response = await createAxiosInstance(true).get(LIST_USERS, {
      params: {
        skip: skip,
        take: take,
        search: search,
        lineId: lineId,
        stationId: stationId,
        roleNames: roleNames,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function fetchUsers() {
  try {
    const response = await createAxiosInstance(true).get(FETCH_USERS);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function listRoles({ skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(LIST_ROLES, {
      params: {
        skip: skip,
        take: take,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
export async function listUserRoles({OfficerId,skip, take, Search} ) {
  try {
    const response = await createAxiosInstance(true).get(LIST_USER_STATION_QUEUES, {
      params: {
        OfficerId: OfficerId,
        skip: skip,
        take: take,
        Search: Search,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function addUserDetails({officerId,lineId,stationId,dateEffectiveFrom}) {
  try {
    const response = await createAxiosInstance(true).post(ADD_STATION_QUEUE, {
      officerId: officerId,
      lineId: lineId,
      stationId: stationId,
      dateEffectiveFrom: dateEffectiveFrom,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function addUserDetail(id) {
  try {
    const response = await createAxiosInstance(true).get(ADD_STATION_DETAIL, {
      params: {
        userStationQueueId: id,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function addUserEdit({userStationQueueId,lineId,stationId,dateEffectiveFrom}) {
  try {
    const response = await createAxiosInstance(true).post(ADD_STATION_EDIT, {
      userStationQueueId,
      lineId,
      stationId,
      dateEffectiveFrom,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getUserDetails(officerId) {
  try {
    const response = await createAxiosInstance(true).get(GET_USER, {
      params: {
        officerId: officerId,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function addUser(data) {
  try {
    const response = await createAxiosInstance(true).post(ADD_USER, data);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function changeUserRoles(data) {
  try {
    const response = await createAxiosInstance(true).post(
      CHANGE_USER_ROLES,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editUser(data) {
  try {
    const response = await createAxiosInstance(true).post(EDIT_USER, data);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function activateUser(id) {
  try {
    const response = await createAxiosInstance(true).post(ACTIVATE_USER, {
      userId: id
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deactivateUser(id) {
  try {
    const response = await createAxiosInstance(true).post(DEACTIVATE_USER, {
      userId: id
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateResignDate(userId, date) {
  try {
    const response = await createAxiosInstance(true).post(CHANGE_USER_RESIGN_DATE, {
      userId,
      resignDate: date,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}