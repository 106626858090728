import get from "lodash.get";
import sortBy from "lodash.sortby";
import { getStationCashAccounts } from "../../services/cashManagement.service";

const ACCOUNT_TYPES = {
  PSC: 1,
  AFC: 2,
  Contractor: 3,
  GTM: 4,
};

const state = () => ({
  accounts: [],
  psms: [],
  selectedPSCId: null,
});

const getters = {
  hasAccounts: (state) => state.accounts.length > 0,
  pscList: ({ accounts }) =>
    accounts.filter(
      ({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.PSC
    ),
  pscOptions: ({ accounts }) => {
    const data = accounts
      .filter(({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.PSC)
      .map((item) => ({
        label: item.accountName,
        value: item.accountId,
      }));
    return sortBy(data, "label");
  },
  pscId: ({ selectedPSCId }) => {
    return selectedPSCId;
  },
  pscName: ({ accounts, selectedPSCId }) => {
    const psc = accounts.find(({ accountId }) => accountId === selectedPSCId);
    return get(psc, "accountName");
  },
  afcId: ({ accounts }) => {
    const afc = accounts.find(
      ({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.AFC
    );
    return get(afc, "accountId", null);
  },
  gtmList: ({ accounts }) =>
    accounts.filter(
      ({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.GTM
    ),
  gtmOptions: ({ accounts }) => {
    const data = accounts
      .filter(({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.GTM)
      .map((item) => ({
        label: item.accountName,
        value: item.accountId,
      }));
    return sortBy(data, "label");
  },

  hasPSMs: (state) => state.psms.length > 0,
  getPSMOfPSC: ({ psms }) => (pscId) =>
    psms.filter(({ cashAccountId }) => cashAccountId === pscId),
};

const mutations = {
  setAccounts(state, list = []) {
    state.accounts = [...list];
  },
  setPSMs(state, list = []) {
    state.psms = [...list];
  },
  setDefaultSelectedPSCId(state, list = []) {
    const psc = list.find(
      ({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.PSC
    );
    const id = get(psc, "accountId", null);
    if (id) state.selectedPSCId = id;
  },
  setSelectedPSCId(state, id) {
    state.selectedPSCId = id;
  },
  reset(state) {
    state.accounts = [];
    state.psms = [];
    state.selectedPSCId = null;
  },
};

const actions = {
  loadData: async function({ state, commit, rootGetters }) {
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];
    const lineId = rootGetters['selectedStation/lineId']
    const result = await getStationCashAccounts({
      stationId,
      lineId,
    });
    if (result.success) {
      commit("setAccounts", get(result, "payload.accounts", []));
      commit("setPSMs", get(result, "payload.psMs", []));
      const pscIds = get(result, "payload.accounts", [])
        .filter(({ accountTypeEnum }) => accountTypeEnum == ACCOUNT_TYPES.PSC)
        .map(({ accountId }) => accountId);
      const containId = pscIds.includes(state.selectedPSCId);
      if (!containId)
        commit("setDefaultSelectedPSCId", get(result, "payload.accounts", []));
      return result;
    } else {
      return result;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
