import store from '@/store'
import get from 'lodash.get';

export default (toRoute) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const canCreateDiscrepancyReport = store.getters['auth/canCreatePSCDiscrepancyReport'] ||
    store.getters['auth/canCreateAFCDiscrepancyReport'];
  const isToCMRoute = get(toRoute, 'matched.0.path') === '/cash-mgnt';
  if (isAuthenticated && isToCMRoute && canCreateDiscrepancyReport)
    store.dispatch('cashManagement/stationTransactions/loadData');
} 