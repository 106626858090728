import { ATTACHMENT_CATEGORIES } from "../constants/APIEnums/attachmentEnums";
import {
  DOWNLOAD_FILE,
  UPLOAD_FILE,
  FILESERVICE_HEALTH_CHECK,
  UPLOAD_SAP_ROSTER,
  UPLOAD_SAP_OT,
  UPLOAD_SAP_LEAVE,
  UPLOAD_SAP_RESIGNEE,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function fileServiceHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(
      FILESERVICE_HEALTH_CHECK
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function downloadFile({ fileId, preview = false }) {
  try {
    const response = await createAxiosInstance(true).get(DOWNLOAD_FILE, {
      params: {
        FileId: fileId,
        preview: preview,
      },
      responseType: "blob",
    });
    return response;
  } catch (e) {
    return errorHandler(e);
  }
}

export async function uploadFile(files, category = ATTACHMENT_CATEGORIES.OTHERS, config = {}) {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
      formData.append("category", category.toString());
    });
    const response = await createAxiosInstance(true).post(
      UPLOAD_FILE,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

/* FOR TESTING */
export async function uploadSapRoster(file, config = {}) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await createAxiosInstance(true).post(
      UPLOAD_SAP_ROSTER,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}


export async function uploadSapLeave(file, config = {}) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await createAxiosInstance(true).post(
      UPLOAD_SAP_LEAVE,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}


export async function uploadSapOt(file, config = {}) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await createAxiosInstance(true).post(
      UPLOAD_SAP_OT,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function uploadSapResignee(file, config = {}) {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await createAxiosInstance(true).post(
      UPLOAD_SAP_RESIGNEE,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
/* END FOR TESTING */