import ROLES from "../../../Roles.json";

export default {
  // PSC
  canCreateShiftEndSummary(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement;
  },
  canCreateCashDeclarationForm(state) {
    return state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager;
  },
  canDeleteCashDeclarationForm(state) {
    return state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager;
  },
  canCreatePSMManualRecord(state) {
    return state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canEditPSMManualRecord(state) {
    return state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canCreateCashFloatRecord(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager;
  },
  canCreateTicketAndEvent(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager;
  },
  canCreatePSCDiscrepancyReport(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  // AFC
  canUseCoinTube(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager;
  },
  canCreateCoinFlush(state) {
    return state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager;
  },
  canInteractWithCashBox(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager;
  },
  canMoveAFCCashBags(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager;
  },
  canCreateSCCF(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager;
  },
  canCreateStationTransfer(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager;
  },
  canCreateAFCDiscrepancyReport(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  // Others
  canViewCMConfiguration(state) {
    return state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canEditCMCutOffTime(state) {
    return state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canEditCMCashThreshold(state) {
    return state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canViewCollectionSummary(state) {
    return state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canUpdateCollectionSummary(state) {
    return state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canCreateCashOrder(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager;
  },
  canApproveCashOrder(state) {
    return state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement;
  },
  canViewAdminCashOrderReport(state) {
    return state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager ||
      state.roleKey == ROLES.SystemAdmin;
  },
  canViewCMDiscrepancyReport(state) {
    return state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canAssignDiscrepancyReport(state) {
    return state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager;
  },
  canUpdateDiscrepancyReport(state) {
    return state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager;
  },
  canApproveOrRejectDiscrepancyReport(state) {
    return state.roleKey === ROLES.ServiceOperationManager;
  }
}