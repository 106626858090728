import stationCashAccounts from "./stationCashAccounts.module";
import configuration from "./configuration.module";
import cashOrders from "./cashOrders.module";
import stationTransactions from "./stationTransactions.module";

export default {
  namespaced: true,
  modules: {
    stationCashAccounts,
    configuration,
    cashOrders,
    stationTransactions,
  },
  actions: {
    loadStationCashAccounts: async function({ dispatch, rootGetters }) {
      const hasAccounts =
        rootGetters["cashManagement/stationCashAccounts/hasAccounts"];
      if (!hasAccounts) await dispatch("stationCashAccounts/loadData");
      return;
    },
    loadInitialData: async function({ dispatch }) {
      dispatch("configuration/getDataAsync");
      await dispatch("stationCashAccounts/loadData");
      dispatch("cashOrders/loadCashOrderList");
      dispatch("stationTransactions/loadData");
    },
    reset: function({ commit }) {
      commit("stationCashAccounts/reset");
      commit("configuration/reset");
      commit("cashOrders/reset");
      commit("stationTransactions/reset");
    },
  },
};
