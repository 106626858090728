import ROLES from "../../../Roles.json";

export default {
  canUpdateHealthDeclaration(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canUpdateSMDutyKeys(state) {
    return (
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canUpdateSMDutyItems(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.StationManagerControlRoom ||
      state.roleKey === ROLES.OpsAdmin
    );
  }
}