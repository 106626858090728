import get from "lodash.get";

const state = () => ({
  token: null,
});

const getters = {
  getToken(state) {
    return get(state, "token", {});
  },
};

const mutations = {
  setToken(state, value) {
    state.token = value;
  },
  reset(state) {
    state.token = null;
  },
};

const actions = {
  setToken: async ({ commit }, value) => {
    commit("setToken", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
