import ROLES from "../../Roles.json";

export default [
  {
    path: "/fault-mgnt",
    component: () =>
      import("../views/fault-management/FaultManagementDefaultWrapper.vue"),
    meta: {
      title: "screens.faultManagement.Main.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Fault Management Main Page",
        component: () =>
          import("../views/fault-management/StationFault/Index.vue"),
        meta: {
          title: "screens.faultManagement.Main.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
            ROLES.OpsAdmin,
          ],
        },
      },
      {
        path: "station-occurrence",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.faultManagement.StationOccurrence.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Station Occurrence Main Page",
            component: () =>
              import("../views/fault-management/StationOccurence/Index.vue"),
            meta: {
              title: "screens.faultManagement.StationOccurrence.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "create",
            name: "Create Station Occurrence",
            component: () =>
              import(
                "../views/fault-management/StationOccurence/CreateStationOccurence.vue"
              ),
            meta: {
              title:
                "screens.faultManagement.StationOccurrence.create.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "view/:occurenceId",
            name: "View Station Occurrence",
            component: () =>
              import(
                "../views/fault-management/StationOccurence/ViewStationOccurence.vue"
              ),
            meta: {
              title: "screens.faultManagement.StationOccurrence.view.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "view-transaction/:transactionId",
            name: "View Station Occurence Transaction Details",
            component: () =>
              import(
                "../views/fault-management/StationOccurence/ViewTransactionDetails.vue"
              ),
            meta: {
              title:
                "screens.faultManagement.StationOccurrence.viewTransaction.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "edit/:occurenceId",
            name: "Edit Station Occurrence",
            component: () =>
              import(
                "../views/fault-management/StationOccurence/EditStationOccurence.vue"
              ),
            meta: {
              title: "screens.faultManagement.StationOccurrence.edit.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "configurations",
            name: "Configurations Page",
            component: () =>
              import("../views/fault-management/Configurations/Index.vue"),
            meta: {
              title:
                "screens.faultManagement.StationOccurrence.configurations.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.OpsAdmin,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
        ],
      },
      {
        path: "station-fault",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.faultManagement.StationFault.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Station Fault Main Page",
            component: () =>
              import("../views/fault-management/StationFault/Index.vue"),
            meta: {
              title: "screens.faultManagement.StationFault.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "create",
            name: "Create Station Fault",
            component: () =>
              import(
                "../views/fault-management/StationFault/CreateStationFault.vue"
              ),
            meta: {
              title: "screens.faultManagement.StationFault.create.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "view-transaction/:transactionId",
            name: "View Station Fault Transaction Details",
            component: () =>
              import(
                "../views/fault-management/StationFault/ViewTransactionDetails.vue"
              ),
            meta: {
              title:
                "screens.faultManagement.StationFault.viewTransaction.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
              ],
            },
          },
          {
            path: "view/:faultId",
            name: "View Station Fault",
            component: () =>
              import(
                "../views/fault-management/StationFault/ViewStationFault.vue"
              ),
            meta: {
              title: "screens.faultManagement.StationFault.view.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
              ],
            },
          },
          {
            path: "edit/:faultId",
            name: "Edit Station Fault",
            component: () =>
              import(
                "../views/fault-management/StationFault/EditStationFault.vue"
              ),
            meta: {
              title: "screens.faultManagement.StationFault.edit.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
        ],
      },
      {
        path: "e-booklets",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.faultManagement.EBooklets.pageTitle",
        },
        children: [
          {
            path: "",
            name: "E-booklets Main Page",
            component: () =>
              import("../views/fault-management/EBooklets/Index.vue"),
            meta: {
              title: "screens.faultManagement.EBooklets.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
                ROLES.OpsAdmin,
              ],
            },
          },
          {
            path: "create",
            name: "Create Fault E-booklet",
            component: () =>
              import(
                "../views/fault-management/EBooklets/CreateStationEBooklet.vue"
              ),
            meta: {
              title: "screens.faultManagement.EBooklets.create.pageTitle",
              requiresRoles: [ROLES.OpsAdmin],
            },
          },
          {
            path: "view/:faultEBookletId",
            name: "View Fault E-booklet",
            component: () =>
              import(
                "../views/fault-management/EBooklets/ViewStationEBooklet.vue"
              ),
            meta: {
              title: "screens.faultManagement.EBooklets.view.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.StationManagerControlRoom,
              ],
            },
          },
          {
            path: "edit/:faultEBookletId",
            name: "Edit Fault E-booklet",
            component: () =>
              import(
                "../views/fault-management/EBooklets/EditStationEBooklet.vue"
              ),
            meta: {
              title: "screens.faultManagement.EBooklets.edit.pageTitle",
              requiresRoles: [ROLES.OpsAdmin],
            },
          },
        ],
      },
    ],
  },
];
