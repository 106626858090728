import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_HEALTH_CHECK, GENERATE_OTP, AUTH_REFRESH_TOKEN } from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function authHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(AUTH_HEALTH_CHECK);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function login(loginData) {
  try {
    const response = await createAxiosInstance(false).post(
      AUTH_LOGIN,
      loginData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function logout() {
  try {
    const response = await createAxiosInstance(true).get(AUTH_LOGOUT);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function refreshToken() {
  try {
    const response = await createAxiosInstance(true).post(AUTH_REFRESH_TOKEN);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function generateMobileOTP() {
  try {
    const response = await createAxiosInstance(true).post(GENERATE_OTP, {});
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}