import store from '@/store'

export default (to, _, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const allowsAnonymous = to.meta.allowsAnonymous
  if (to.name !== 'Login' && !allowsAnonymous && !isAuthenticated) {
    next({ name: 'Login'})
  } else if (Array.isArray(to.meta.requiresRoles) && to.meta.requiresRoles.length > 0) {
    const isAuthorized = to.meta.requiresRoles.includes(store.state.auth.roleKey)
    if (!isAuthorized)
      next({ name: '403 Forbidden' })
    else 
      next()
  } else {
    next();
  }
}