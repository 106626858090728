import { isMobileSizeDisplay } from "../helpers/browserHelpers";

const state = () => ({
  collapseSideBar: false,
  userStation: {}
});

const getters = {

}

const mutations = {
  setCollapseSideBar(state, value) {
    state.collapseSideBar = value;
  },
  userStationSet(state, value) {
    state.userStation = value;
  },
  reset(state) {
    state.collapseSideBar = false;
  }
};

const actions = {
  setup({ commit }) {
    if (isMobileSizeDisplay())
      commit('setCollapseSideBar', true);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}