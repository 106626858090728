import ROLES from "../../Roles.json";

export default [
  {
    path: '/manpower',
    component: () => import('../views/manpower/Main.vue'),
    meta: {
      title: 'screens.manpower.Main.pageTitle',
    },
    children: [
      {
        path: 'supervisor-roster',
        name: 'Supervisor Roster',
        component: () => import(/* webpackChunkName: "supervisor-roster" */ '../views/manpower/SupervisorRoster/Index.vue'),
        meta: {
          title: 'screens.manpower.SupervisorRoster.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
            ROLES.OperationControlCentre,
          ],
        },
        alias: '/manpower'
      },
      {
        path: "station-roster",
        name: "Station Roster",
        component: () =>
          import(
            /* webpackChunkName: "station-roster" */ "../views/manpower/StationRoster.vue"
          ),
        meta: {
          title: 'screens.manpower.StationRoster.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
          ],
        },
      },
      {
        path: "personal-roster",
        name: "Personal Roster",
        component: () =>
          import(
            /* webpackChunkName: "personal-roster" */ "../views/manpower/PersonalRoster.vue"
          ),
        meta: {
          title: 'screens.manpower.PersonalRoster.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
            ROLES.OperationControlCentre,
          ],
        },
      },
      {
        path: "edit-roster/:rosterId",
        name: "Edit Roster",
        component: () =>
          import(
            /* webpackChunkName: "edit-roster" */ "../views/manpower/EditRoster.vue"
          ),
        meta: {
          title: 'screens.manpower.EditRoster.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
          ],
        }
      },
      {
        path: "availability",
        name: "Availability",
        component: () =>
          import(
            /* webpackChunkName: "availability" */ "../views/manpower/ListAvailability.vue"
          ),
        meta: {
          title: 'screens.manpower.ListAvailabilty.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
          ],
        },
      },
      {
        path: "ot_volunteers",
        name: "OT_Volunteers",
        component: () =>
          import(
            /* webpackChunkName: "ot_volunteers" */ "../views/manpower/OTVolunteer.Create.vue"
          ),
        meta: {
          title: 'screens.manpower.OTVolunteer.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "ot_unavailable",
        name: "Mark_OT_Unavail",
        component: () =>
          import(
            /* webpackChunkName: "ot_volunteers" */ "../views/manpower/MarkOTUnavailable.vue"
          ),
        meta: {
          title: 'screens.manpower.OTUnavailable.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "mutual_exchange",
        name: "Mutual Exchange",
        component: () =>
          import(
            /* webpackChunkName: "mutual_exchange" */ "../views/manpower/MutualExchange/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.MutualExchange.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "staff_unavailable/:rosterId",
        name: "Staff Unavailability",
        component: () =>
          import(
            /* webpackChunkName: "staff_unavailable" */ "../views/manpower/StaffUnavailability.vue"
          ),
        meta: {
          title: 'screens.manpower.StaffUnavailability.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "staff_replacement/:rosterId",
        name: "Staff Replacement",
        component: () =>
          import(
            /* webpackChunkName: "staff_replacement" */ "../views/manpower/StaffReplacement/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.StaffReplacement.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "block_leave",
        name: "Block Leave",
        component: () =>
          import(
            /* webpackChunkName: "block_leave" */ "../views/manpower/BlockLeave.vue"
          ),
        meta: {
          title: 'screens.manpower.BlockLeave.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.StationManagerControlRoom,
          ],
        },
      },
      {
        path: "planned_ot/:rosterId",
        name: "Planned OT",
        component: () =>
          import(
            /* webpackChunkName: "planned_ot" */ "../views/manpower/PlannedOT.vue"
          ),
        meta: {
          title: 'screens.manpower.PlannedOT.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "conflicts",
        name: "Roster Conflicts",
        component: () =>
          import(
            "../views/manpower/RosterConflicts/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.Conflicts.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "ot-report",
        name: "OT Hours",
        component: () =>
          import(
            "../views/manpower/OTHours/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.OTHours.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "mom-regulations",
        name: "MOM Regulations",
        component: () =>
          import(
            "../views/manpower/MOMRegulations/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.MOMRegulations.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "leave-reports",
        name: "Leave Reports",
        component: () =>
          import(
            "../views/manpower/LeaveReports/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.LeaveReports.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.OperationControlCentre,
          ],
        },
      },
      {
        path: "pending-replacements",
        name: "Pending Replacements",
        component: () =>
          import(
            "../views/manpower/PendingReplacements/Index.vue"
          ),
        meta: {
          title: 'screens.manpower.PendingReplacements.pageTitle',
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.StationManagerControlRoom,
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "sap-sync-errors",
        name: "SAP Sync Errors",
        component: () =>
          import(
            "../views/manpower/Main.vue"
          ),
        meta: {
          title: 'screens.manpower.SAPSyncError.pageTitle',
          requiresRoles: [
            ROLES.SystemAdmin,
          ],
        },
        children: [
          {
            path: "",
            name: "SAP Sync Errors List",
            component: () => import(
              "../views/manpower/SAPSyncError.vue"
            ),
          },
          {
            path: ':id',
            name: "SAP Sync Errors By File",
            component: () => import(
              "../views/manpower/SAPSyncErrorByFile.vue"
            ),
            meta: {
              title: 'screens.manpower.SAPSyncError.pageTitle',
            },
          }
        ]
      },
      {
        path: 'zone-management',
        name: 'SOM Zone',
        component: () => import(/* webpackChunkName: "zone-info" */ '../views/manpower/ZoneInfo.vue'),
        meta: {
          title: 'screens.manpower.ZoneInfo.pageTitle',
          requiresRoles: [
            ROLES.ServiceOperationManager,
          ],
        },
      },
      {
        path: 'group-management',
        name: 'MSO Group',
        component: () => import(/* webpackChunkName: "group-info" */ '../views/manpower/GroupInfo.vue'),
        meta: {
          title: 'screens.manpower.GroupInfo.pageTitle',
          requiresRoles: [
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
    ]
  },
  /* FOR TESTING */
  {
    path: "/sap",
    name: "SAP",
    component: () =>
      import(
        /* webpackChunkName: "sap" */ "../views/manpower/SAP_Test.vue"
      ),
    meta: {
      title: 'screens.manpower.SAP_Test.pageTitle',
      requiresRoles: [
        ROLES.SystemAdmin,
      ],
    },
  },
]
