import ROLES from "../../Roles.json";

export default [
  {
    path: "/acknowledgements",
    component: () => import("../views/DefaultWrapper.vue"),
    meta: {
      title: "screens.opsComm.Acknowledgement.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Acknowledgements Main Page",
        component: () =>
          import("../views/ops-comms/Acknowledgements/Index.vue"),
        meta: {
          title: "screens.opsComm.Acknowledgement.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
            ROLES.OperationControlCentre,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.CSTLeader,
            ROLES.CSTMember,
          ],
        },
      },
      {
        path: "view/:acknowledgementId",
        name: "View Acknowledgement",
        component: () =>
          import("../views/ops-comms/Acknowledgements/ViewAcknowledgement.vue"),
        meta: {
          title: "screens.opsComm.Acknowledgement.view.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
            ROLES.OperationControlCentre,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.CSTLeader,
            ROLES.CSTMember,
          ],
        },
      },
    ],
  },
  {
    path: "/ops-comm",
    component: () => import("../views/ops-comms/OpsCommDefaultWrapper.vue"),
    meta: {
      title: "screens.opsComm.Main.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Operations Communication Main Page",
        component: () =>
          import("../views/ops-comms/CPUpdates/CPUpdateMainPage.vue"),
        meta: {
          title: "screens.opsComm.Main.pageTitle",
          requiresRoles: [
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.ServiceOperationManager,
            ROLES.FSOManager,
            ROLES.FSOAdministrativeOfficer,
            ROLES.OpsAdmin,
            ROLES.SystemAdmin,
          ],
        },
      },
      {
        path: "create",
        name: "Create CP Update",
        component: () =>
          import("../views/ops-comms/CPUpdates/CreateCPUpdate.vue"),
        meta: {
          title: "screens.opsComm.CPUpdate.create.pageTitle",
          requiresRoles: [
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.ServiceOperationManager,
            ROLES.FSOManager,
            ROLES.FSOAdministrativeOfficer,
            ROLES.SystemAdmin,
          ],
        },
      },
      {
        path: "details/:cpUpdateId",
        name: "View CP Update",
        component: () =>
          import("../views/ops-comms/CPUpdates/ViewCPUpdate.vue"),
        meta: {
          title: "screens.opsComm.CPUpdate.view.pageTitle",
          requiresRoles: [
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.ServiceOperationManager,
            ROLES.FSOManager,
            ROLES.FSOAdministrativeOfficer,
            ROLES.OpsAdmin,
            ROLES.SystemAdmin,
          ],
        },
      },
      {
        path: "configurations",
        name: "OPS COMM Configurations Page",
        component: () => import("../views/ops-comms/Configurations/Index.vue"),
        meta: {
          title:
            "screens.faultManagement.StationOccurrence.configurations.pageTitle",
          requiresRoles: [
            ROLES.OpsAdmin,
            ROLES.FSOManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.ServiceOperationManager,
          ],
        },
      },
      {
        path: "report",
        name: "CP Update Report",
        component: () => import("../views/ops-comms/CPUpdates/Report.vue"),
        meta: {
          title: "screens.opsComm.CPUpdate.report.pageTitle",
          requiresRoles: [
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.ServiceOperationManager,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.OpsAdmin,
            ROLES.SystemAdmin,
          ],
        },
      },
      {
        path: "user-report",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.opsComm.CPUpdate.userReport.pageTitle",
          requiresRoles: [
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.ServiceOperationManager,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.OpsAdmin,
            ROLES.SystemAdmin,
          ],
        },
        children: [
          {
            path: "",
            name: "Officer Acknowledgement Report",
            component: () =>
              import("../views/ops-comms/CPUpdates/OfficerReport.vue"),
            meta: {
              title: "screens.opsComm.CPUpdate.userReport.pageTitle",
              requiresRoles: [
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.ServiceOperationManager,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
                ROLES.OpsAdmin,
                ROLES.SystemAdmin,
              ],
            },
          },
          {
            path: "officer/:recipientId",
            name: "Individual Officer Acknowledgement Report",
            component: () =>
              import("../views/ops-comms/CPUpdates/IndividualReport.vue"),
            meta: {
              title: "screens.opsComm.CPUpdate.individualReport.pageTitle",
              requiresRoles: [
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.ServiceOperationManager,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
                ROLES.OpsAdmin,
                ROLES.SystemAdmin,
              ],
            },
          },
        ],
      },
    ],
  },
];
