import loginRedirect from './helpers/loginRedirect'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    beforeEnter: loginRedirect,
    meta: { allowsAnonymous: true },
  },
  {
    path: '/test-login',
    name: 'TestLogin',
    component: () => import(/* webpackChunkName: "test-login" */ '../views/login/TestLogin.vue'),
    meta: { allowsAnonymous: true },
  },
  {
    path: '/m-login',
    name: 'Login Mobile',
    component: () => import(/* webpackChunkName: "test-login" */ '../views/login/mobile/LoginMobile.vue'),
    meta: { allowsAnonymous: true },
  },
  {
    path: '/m-login-success',
    name: 'Login Mobile Success',
    component: () => import(/* webpackChunkName: "test-login" */ '../views/login/mobile/LoginMobileSuccess.vue'),
    meta: { allowsAnonymous: true },
  },
  {
    path: '/m-logout',
    name: 'Logout Mobile',
    component: () => import(/* webpackChunkName: "test-login" */ '../views/login/mobile/LogoutMobile.vue'),
    meta: { allowsAnonymous: true },
  },
]
