import ROLES from "../../../Roles.json";

export default {
  showNotBaseStationAlert(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canManageManpower(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationManagerControlRoom ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canViewManpower(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canMarkUnavailability(state) {
    return (
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.StationManagerControlRoom ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canBlockLeave(state) {
    return (
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.StationManagerControlRoom ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canAssignCovering(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canExtendShift(state) {
    return (
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canPlanOT(state) {
    return (
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canSelfVolunteerForOt(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManagerControlRoom
    );
  },
  canTakeActionInCurrentStation: (state, getters, _, rootGetters) => (stationId) => {
    const userStationId = getters['userStationId'];
    switch (state.roleKey) {
      case ROLES.StationManager:
      case ROLES.StationManagerControlRoom:
      case ROLES.SeniorStationManager:
        return userStationId == stationId;
      case ROLES.ServiceOperationManager:
        return rootGetters['smrtResource/isSameGroup'](userStationId, stationId);
      case ROLES.ManagerStationOperation:
      case ROLES.StationOperationManagement:
        return rootGetters['smrtResource/isSameGroup'](userStationId, stationId);
      default:
        return false;
    }
  }
}