import ROLES from "../../Roles.json";

export default [
  {
    path: '/wayfinding',
    component: () => import("../views/DefaultWrapper.vue"),
    meta: {
      title: 'screens.tourist.Main.pageTitle',
    },
    children: [
      {
        path: "",
        name: "Wayfinding Main Page",
        component: () => import('../views/tourist/MainPage.vue'),
        meta: {
          title: 'screens.tourist.Main.pageTitle',
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.StationManagerControlRoom,
            ROLES.OperationControlCentre,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.CSTLeader,
            ROLES.CSTMember,
            ROLES.OpsAdmin,
          ],
        },
      },
      {
        path: "configure-map",
        name: "Wayfinding Configure Map",
        component: () => import('../views/tourist/Create.vue'),
        meta: {
          title: 'screens.tourist.Create.pageTitle',
          requiresRoles: [
            ROLES.OpsAdmin,
          ],
        },
      },
    ]
  }
]