import ROLES from "../../../Roles.json";

export default {
  canEditOpsCommExpireThreshold(state) {
    return (
      state.roleKey === ROLES.OpsAdmin ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canViewPersonalAcknowledgements(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.StationManagerControlRoom ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.OperationControlCentre
    );
  },
  canCreateOpsCommCirculars(state) {
    return (
      state.roleKey === ROLES.SystemAdmin ||
      state.roleKey === ROLES.FSOManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canCreateOpsCommCircularsForBothLines(state) {
    return (
      state.roleKey === ROLES.SystemAdmin ||
      state.roleKey === ROLES.FSOManager ||
      state.roleKey === ROLES.FSOAdministrativeOfficer
    );
  }
}