import ROLES from "../../../Roles.json";

export default {
  canUpdateDigitalRecords(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager
    );
  },
  canViewDigitalRecords(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  canEditInventoryNotifications(state) {
    return state.roleKey === ROLES.OpsAdmin;
  },
  canViewInventoryNotifications(state) {
    return (
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canEditItemTypes(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canEditFirstAidBox(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canViewItemTypes(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canEditItemLocations(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canViewItemLocations(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.OpsAdmin
    );
  },
  canPlaceInventoryOrder(state) {
    return (
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager
    );
  },
  canUpdateInventoryOrder(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager
    );
  },
  canApproveInventoryOrder(state) {
    return state.roleKey === ROLES.ServiceOperationManager;
  },
  canRejectInventoryOrder(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager
    );
  },
  canViewInventoryOrder(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement ||
      state.roleKey === ROLES.FSOAdministrativeOfficer ||
      state.roleKey === ROLES.FSOManager
    );
  },
  canReceiveInventoryOrder(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.StationManager
    );
  },
  viewInjuryIncidents(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    );
  },
  createInjuryIncidents(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager
    );
  },
  canVerifyFirstAidBox(state) {
    return (
      state.roleKey === ROLES.ServiceOperationManager ||
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager
    )
  },
  canUseSmartCard(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    )
  },
  canUpdateSmartCard(state) {
    return (
      state.roleKey === ROLES.AssistantStationManager ||
      state.roleKey === ROLES.SeniorAssistantStationManager ||
      state.roleKey === ROLES.StationManager ||
      state.roleKey === ROLES.SeniorStationManager ||
      state.roleKey === ROLES.ManagerStationOperation ||
      state.roleKey === ROLES.StationOperationManagement
    )
  },
}