import ROLES from "../../Roles.json";

export default [
  {
    path: "/admin-config",
    component: () =>
      import("../views/DefaultWrapper.vue"),
    meta: {
      title: "screens.adminConfig.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Admin Configuration",
        component: () =>
          import("../views/adminConfig/Index.vue"),
        meta: {
          title: "screens.adminConfig.pageTitle",
          requiresRoles: [
            ROLES.OpsAdmin,
          ],
        },
      },
      {
        path: "edit-email/:asomsNotificationTemplateId",
        name: "Edit Email Template",
        component: () =>
          import("../views/adminConfig/EditEmailTemplate.vue"),
        meta: {
          title: "screens.adminConfig.editEmailTemplate.pageTitle",
          requiresRoles: [
            ROLES.OpsAdmin,
          ],
        },
      },
    ]
  }
]