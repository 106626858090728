import store from '@/store'

export default (to, from, next) => {
  if (store.getters['auth/isSysAdmin']) {
    next({ name: 'Users' });
  } else if (store.getters['auth/isOpsAdmin']) {
    next({ name: 'Admin Configuration' })
  } else if (store.getters['auth/isMSO']) {
    next({ name: 'MSO Group' })
  } else if (store.getters['auth/isSOM']) {
    next({ name: 'SOM Zone' })
  } else if (store.getters['auth/canManageManpower']) {
    next({ name: 'Supervisor Roster' })
  } else if (store.getters['auth/canViewManpower']) {
    next({ name: 'Personal Roster' })
  } else {
    next({ name: "Stations"});
  }
}