import {
  GET_STATION_CASH_ACCOUNTS,
  GET_TICKET_EVENTS_FOR_STATION,
  CREATE_EVENT,
  CLOSE_EVENT,
  GET_TICKET_LOGS,
  GET_TICKET_LOG_DETAILS,
  CREATE_TICKET_LOG,
  EDIT_TICKET_LOG,
  CREATE_PSM_LOG,
  EDIT_PSM_LOG,
  GET_PSM_LOGS,
  GET_PSM_DETAILS,
  GET_CERTIS_BAG_TRANSACTIONS,
  GET_CERTIS_BAG_DETAILS,
  CREATE_SHIFT_END_SUMMARY,
  MOVE_CERTIS_BAGS_PSC,
  CREATE_CERTIS_BAG,
  OPEN_CERTIS_BAG,
  DISCARD_CERTIS_BAG,
  GET_PSC_CASH_FLOAT_MAIN_PAGE_DATA,
  GET_PRE_ENCODED_MAIN_PAGE_DATA,
  GET_PSM_MAIN_PAGE_DATA,
  CREATE_CASH_DECLARATION_FORM,
  DELETE_CASH_DECLARATION_FORM,
  GET_CASH_DECLARATION_FORM_LIST,
  GET_CASH_DECLARATION_FORM_DETAILS,
  SIGN_CASH_DECLARATION_FORM,
  GET_CASH_MANAGEMENT_CONFIG,
  UPDATE_STATION_CASH_THRESHOLD,
  UPDATE_COINTUBE_CONFIG,
  UPDATE_CUTOFF_TIME,
  GET_CASH_ORDER_LIST,
  GET_ADMIN_CASH_ORDER_LIST,
  GET_CASH_ORDER_DETAILS,
  CREATE_CASH_ORDER,
  APPROVE_CASH_ORDER,
  REJECT_CASH_ORDER,
  CREATE_STATION_CASH_COLLECTION_FORM,
  CREATE_STATION_TRANSFER,
  MOVE_CASH_BAGS_AFC,
  USE_COIN_TUBE,
  COIN_FLOAT_SUMMARY,
  AFC_SUMMARY,
  CASH_BOX_SUMMARY,
  CREATE_CASH_BOX,
  DELETE_CASH_BOX,
  MOVE_CASH_BOX,
  AFC_CASH_BAG_SUMMARY,
  CASHMANAGEMENT_HEALTH_CHECK,
  CREATE_COIN_FLUSH,
  GET_CASH_BOX_DETAILS,
  PSC_SUMMARY,
  GET_PSC_SHIFT_INFO,
  MOVE_TE_BAGS_FROM_PSC,
  COIN_FLUSH_SUMMARY,
  LIST_COIN_FLUSH,
  CREATE_DISCREPANCY_REPORT,
  DISCREPANCY_REPORT_LIST,
  REJECT_DISCREPANCY_REPORT,
  CLOSE_DISCREPANCY_REPORT,
  ASSIGN_DISCREPANCY_REPORT,
  APPROVE_DISCREPANCY_REPORT,
  EDIT_DISCREPANCY_REPORT,
  VALIDATE_CERTIS_REPORT,
  CM_VALIDATE_SEP_REPORT,
  CREATE_SEP_REPORT,
  VALIDATE_SEP_REPORT,
  UPLOAD_CERTIS_REPORT,
  UPLOAD_SEP_REPORT,
  GET_COLLECTION_SUMMARY,
  GET_COLLECTION_REPORT,
  LIST_BAG_STATUS,
  EDIT_CERTIS_BAG_TRANSACTION,
  DISCREPANCY_REPORT_DETAILS,
  LIST_STATION_CASH_COLLECTION_FORM,
  GET_STATION_CASH_COLLECTION_FORM,
  LIST_STATION_TRANSFERS,
  GET_STATION_TRANSFER,
  VOID_COIN_FLUSH,
  STATION_CASH_SUMMARY,
  GET_PSM_TRANSACTION_DETAILS,
  STATION_TRANSACTION_LIST,
  MY_DISCREPANCY_REPORTS,
  AFC_CASH_TRANSACTION_LIST,
  GET_CERTIS_REPORT_ACTIVITY_LOG,
  GET_SCCF_CASH_OUT,
  PSM_VS_SEP_VS_SCCF,
  GET_SCCF_CASH_IN,
  CISCO_VS_COVER
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { successHandler, errorHandler } from "./serviceHandlers";
import { getURLSearchParams } from "./service.helpers";

export async function cashMngtHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(
      CASHMANAGEMENT_HEALTH_CHECK
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationCashSummary(stationId, lineId) {
  try {
    const response = await createAxiosInstance(true).get(STATION_CASH_SUMMARY, {
      params: {
        stationId,
        lineId,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createEvent(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_EVENT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function closeEvent(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CLOSE_EVENT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createTicketLog(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_TICKET_LOG,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editTicketLog(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_TICKET_LOG,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfTicketEvents({
  stationId,
  lineId,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_TICKET_EVENTS_FOR_STATION,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationCashAccounts({ stationId, lineId }) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_STATION_CASH_ACCOUNTS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getTicketLogs({
  accountId,
  startDate,
  endDate,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        accountId,
        startDate,
        endDate,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(GET_TICKET_LOGS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getTicketLogDetails({
  id,
}) {
  try {
    const params = { id };
    const response = await createAxiosInstance(true).get(GET_TICKET_LOG_DETAILS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createPsmLog(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_PSM_LOG,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editPsmLog(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_PSM_LOG,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSMDetails({ id }) {
  try {
    const params = getURLSearchParams({
      data: {
        id,
      },
    });
    const response = await createAxiosInstance(true).get(GET_PSM_DETAILS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSMTransactionDetails({ transactionId }) {
  try {
    const params = getURLSearchParams({
      data: {
        transactionId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_PSM_TRANSACTION_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSMLogs({
  accountId,
  startDate,
  endDate,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        accountId,
        startDate,
        endDate,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(GET_PSM_LOGS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createShiftEndSummary(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_SHIFT_END_SUMMARY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSCShiftInfo({ pSCId }) {
  const params = getURLSearchParams({
    data: {
      pSCId,
    },
  });
  try {
    const response = await createAxiosInstance(true).get(GET_PSC_SHIFT_INFO, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCertisBagTransactions({
  accountId,
  bagNo,
  startDate,
  endDate,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        accountId,
        bagNo,
        startDate,
        endDate,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_CERTIS_BAG_TRANSACTIONS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function listBagStatus(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      LIST_BAG_STATUS,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCertisBagDetails({ id, bagNo }) {
  try {
    const params = getURLSearchParams({
      data: {
        id,
        bagNo,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_CERTIS_BAG_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function moveCertisBagsPSC(data) {
  try {
    const response = await createAxiosInstance(true).post(
      MOVE_CERTIS_BAGS_PSC,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function moveTEBagsPSC(data) {
  try {
    const response = await createAxiosInstance(true).post(
      MOVE_TE_BAGS_FROM_PSC,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createCertisBag(data) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_CERTIS_BAG,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function openCertisBag(data) {
  try {
    const response = await createAxiosInstance(true).post(
      OPEN_CERTIS_BAG,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function discardCertisBag(data) {
  try {
    const response = await createAxiosInstance(true).post(
      DISCARD_CERTIS_BAG,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editCertisBagTransaction({
  transactionId,
  isVoid = true,
  remarks,
}) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_CERTIS_BAG_TRANSACTION,
      {
        transactionId,
        isVoid,
        remarks,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSCCashFloatMainPageData({ pSCId, searchDateFrom, searchDateTo}) {
  try {
    const params = getURLSearchParams({
      data: {
        pSCId,
        searchDateFrom,
        searchDateTo
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_PSC_CASH_FLOAT_MAIN_PAGE_DATA,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPreEncodedMainPageData({ pSCId }) {
  try {
    const params = getURLSearchParams({
      data: {
        pSCId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_PRE_ENCODED_MAIN_PAGE_DATA,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSMMainPageData({
  pSCId,
  skip = 0,
  take = 100,
  search,
  searchDateFrom,
  searchDateTo,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        pSCId,
        search,
        searchDateFrom,
        searchDateTo,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_PSM_MAIN_PAGE_DATA,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createCashDeclarationForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_CASH_DECLARATION_FORM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteCashDeclarationForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_CASH_DECLARATION_FORM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCashDeclarationFormList({
  skip = 0,
  take = 100,
  search,
  activeOnly = true,
  stationId,
  lineId,
  searchDateFrom,
  searchDateTo
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        search,
        activeOnly,
        stationId,
        lineId,
        searchDateFrom,
        searchDateTo
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_CASH_DECLARATION_FORM_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCashDeclarationFormDetails({ cashDeclarationFormId }) {
  try {
    const params = getURLSearchParams({
      data: {
        cashDeclarationFormId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_CASH_DECLARATION_FORM_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function signCashDeclarationForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      SIGN_CASH_DECLARATION_FORM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSCSummary(pscAccountId) {
  try {
    const response = await createAxiosInstance(true).get(PSC_SUMMARY, {
      params: {
        accountId: pscAccountId,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getAFCSummary(afcAccountId) {
  try {
    const response = await createAxiosInstance(true).get(AFC_SUMMARY, {
      params: {
        accountId: afcAccountId,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

/** AFC CASH BAG */
export async function getAFCCashBagSummary(afcAccountId) {
  try {
    const response = await createAxiosInstance(true).get(AFC_CASH_BAG_SUMMARY, {
      params: {
        aFCId: afcAccountId,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function moveCashBag(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      MOVE_CASH_BAGS_AFC,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfCashBagTransaction({aFCId, searchDateFrom, searchDateTo}) {
  
  console.log('afcAccountId:',aFCId)
  console.log('searchDateFrom:',searchDateFrom)
  console.log('searchDateTo:',searchDateTo)
  try {
    const response = await createAxiosInstance(true).get(AFC_CASH_TRANSACTION_LIST, {
      params: {
        aFCId: aFCId,
        searchDateFrom:searchDateFrom,
        searchDateTo:searchDateTo
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}


/** END AFC CASH BAG */

/** AFC COIN TUBE */
export async function getCoinFloatSummary(afcAccountId) {
  try {
    const response = await createAxiosInstance(true).get(COIN_FLOAT_SUMMARY, {
      params: {
        accountId: afcAccountId,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function useCoinTube(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      USE_COIN_TUBE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
/** END AFC COIN TUBE */

/** AFC CASH BOX */
export async function getCashBoxSummary({aFCId, searchDateFrom, searchDateTo}) {
  try {
    const response = await createAxiosInstance(true).get(CASH_BOX_SUMMARY, {
      params: {
        aFCId,
        searchDateFrom,
        searchDateTo
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCashBoxDetails(data) {
  try {
    const response = await createAxiosInstance(true).post(GET_CASH_BOX_DETAILS, data);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createCashBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_CASH_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteCashBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_CASH_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function moveCashBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      MOVE_CASH_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
/** END AFC CASH BOX */

/** AFC COIN FLUSH */
export async function getCoinFlushSummary(afcAccountId, skip = 0, take = 100) {
  try {
    const response = await createAxiosInstance(true).get(COIN_FLUSH_SUMMARY, {
      params: {
        accountId: afcAccountId,
        skip: skip,
        take: take,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function listCoinFlushForms(afcAccountId, skip = 0, take = 100) {
  try {
    const response = await createAxiosInstance(true).get(LIST_COIN_FLUSH, {
      params: {
        accountId: afcAccountId,
        skip: skip,
        take: take,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createCoinFlushForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_COIN_FLUSH,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function voidCoinFlushForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      VOID_COIN_FLUSH,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
/** END AFC COIN FLUSH */

/** CASH ORDER */
export async function getCashOrderList({ aFCId, skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(GET_CASH_ORDER_LIST, {
      params: {
        aFCId,
        skip,
        take,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getAdminCashOrderList({ aFCId, skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(
      GET_ADMIN_CASH_ORDER_LIST,
      {
        params: {
          aFCId,
          skip,
          take,
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCashOrderDetails(cashOrderId) {
  try {
    const response = await createAxiosInstance(true).get(
      GET_CASH_ORDER_DETAILS,
      {
        params: {
          cashOrderId,
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSCCFList({lineId, stationId, searchDateFrom, searchDateTo, skip = 0, take = 100}) {
  try {
    const response = await createAxiosInstance(true).get(
      LIST_STATION_CASH_COLLECTION_FORM,
      {
        params: {
          lineId,
          stationId,
          searchDateFrom,
          searchDateTo,
          skip,
          take,
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSCCFDetails(id) {
  try {
    const response = await createAxiosInstance(true).get(
      GET_STATION_CASH_COLLECTION_FORM,
      {
        params: {
          id,
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createStationCashCollectionForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_STATION_CASH_COLLECTION_FORM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationTransferList(
  stationId,
  lineId,
  skip = 0,
  take = 100
) {
  try {
    const response = await createAxiosInstance(true).get(
      LIST_STATION_TRANSFERS,
      {
        params: {
          stationId,
          lineId,
          skip,
          take,
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationTransferDetails(id) {
  try {
    const response = await createAxiosInstance(true).get(GET_STATION_TRANSFER, {
      params: {
        id,
      },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createStationTransfer(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_STATION_TRANSFER,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createCashOrder({
  afcId,
  rosterId,
  isSupplierContractor = true,
  otherStationId,
  orderType = 1,
  noOfCashBags = 0,
  cashBagOrderAmount = 0,
  count10Cents = 0,
  count20Cents = 0,
  count50Cents = 0,
  count1Dollar = 0,
  requestedDeliveryDate,
  isUrgent = false,
  remarks,
}) {
  try {
    const response = await createAxiosInstance(true).post(CREATE_CASH_ORDER, {
      afcId,
      rosterId,
      isSupplierContractor,
      otherStationId,
      orderType,
      noOfCashBags,
      cashBagOrderAmount,
      count10Cents,
      count20Cents,
      count50Cents,
      count1Dollar,
      requestedDeliveryDate,
      isUrgent,
      remarks,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function approveCashOrder({ cashOrderId, rosterId }) {
  try {
    const response = await createAxiosInstance(true).post(APPROVE_CASH_ORDER, {
      cashOrderId,
      rosterId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function rejectCashOrder({ cashOrderId, rosterId }) {
  try {
    const response = await createAxiosInstance(true).post(REJECT_CASH_ORDER, {
      cashOrderId,
      rosterId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
/** END CASH ORDER */

/** ADMIN */
export async function getCashManagementConfig() {
  try {
    const response = await createAxiosInstance(true).get(
      GET_CASH_MANAGEMENT_CONFIG
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateCoinTubeConfiguration(data) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_COINTUBE_CONFIG,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateStationCashThreshold(data) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_STATION_CASH_THRESHOLD,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateCutOffTime(data) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_CUTOFF_TIME,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createDiscrepancyReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_DISCREPANCY_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function assignDiscrepancyReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      ASSIGN_DISCREPANCY_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function approveDiscrepancyReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      APPROVE_DISCREPANCY_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editDiscrepancyReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_DISCREPANCY_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getDiscrepancyReports({
  assignTo = [],
  discrepancyNatures = [],
  discrepancyStatuses = [],
  stationIds = [],
  search = null,
  skip = 0,
  take = 1000,
  startDate,
  endDate,
  lineId,
}) {
  try {
    const response = await createAxiosInstance(true).post(
      DISCREPANCY_REPORT_LIST,
      {
        assignTo,
        discrepancyNatures,
        discrepancyStatuses,
        stationIds,
        search,
        skip,
        take,
        startDate,
        endDate,
        lineId,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getMyDiscrepancyReports({
  search = null,
  skip = 0,
  take = 1000,
  stationId,
  searchDateFrom,
  searchDateTo
}) {
  try {
    const response = await createAxiosInstance(true).post(
      MY_DISCREPANCY_REPORTS,
      {
        search,
        skip,
        take,
        stationId,
        searchDateFrom,
        searchDateTo
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getDiscrepancyReportDetails(id) {
  try {
    const response = await createAxiosInstance(true).get(
      DISCREPANCY_REPORT_DETAILS,
      {
        params: {
          id,
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function rejectDiscrepancyReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      REJECT_DISCREPANCY_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function closeDiscrepancyReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CLOSE_DISCREPANCY_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
/** END ADMIN */

/** STATION COLLECTION SUMMARY */
export async function getCollectionSummary() {
  try {
    const response = await createAxiosInstance(true).get(
      GET_COLLECTION_SUMMARY
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCollectionReport({
  skip = 0,
  take = 100,
  startDate,
  endDate,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        startDate,
        endDate,
      },
    });

    const response = await createAxiosInstance(true).get(
      GET_COLLECTION_REPORT,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function validateCertisReport(files) {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    const response = await createAxiosInstance(true).post(
      VALIDATE_CERTIS_REPORT,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function validateCertisSep(files) {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    const response = await createAxiosInstance(true).post(
      CM_VALIDATE_SEP_REPORT,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function validateCertisUpload(data) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_SEP_REPORT,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function validateSePReport(files) {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    const response = await createAxiosInstance(true).post(
      VALIDATE_SEP_REPORT,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function uploadCertisReport(data) {
  try {
    const response = await createAxiosInstance(true).post(
      UPLOAD_CERTIS_REPORT,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function uploadSePReport(data) {
  try {
    const response = await createAxiosInstance(true).post(
      UPLOAD_SEP_REPORT,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCertisReportActivityLog(data) {
  try {
    const response = await createAxiosInstance(true).post(
      GET_CERTIS_REPORT_ACTIVITY_LOG,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }

}

export async function getSccfCashOut(data) {
  try {
    const response = await createAxiosInstance(true).post(
      GET_SCCF_CASH_OUT,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }

}

export async function getSccfCashSep({ 
  lineId,
  stationIds,
  startDate,
  endDate,
  status,
 }) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        stationIds,
        startDate,
        endDate,
        status,
      },
    });
    const response = await createAxiosInstance(true).get(
      PSM_VS_SEP_VS_SCCF,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSccfCashIn(data) {
  try {
    const response = await createAxiosInstance(true).post(
      GET_SCCF_CASH_IN,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }

}

export async function getCiscoVsCover(data) {
  try {
    const response = await createAxiosInstance(true).post(
      CISCO_VS_COVER,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }

}

/** END STATION COLLECTION SUMMARY */

export async function listStationTransactions({ stationId, lineId }) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(
      STATION_TRANSACTION_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export function listPsmInputFieldOptions(){
  return [
    {label: 'ADMIN CHARGE - EXCESS TIME/ LOST CARD/UPGRADE - WAIVED', value: 'ADMIN CHARGE - EXCESS TIME/ LOST CARD/UPGRADE - WAIVED'},
    {label: 'AFC TESTING', value: 'AFC TESTING'},
    {label: 'AUTO TOP-UP SETTLEMENT CASH PAYMENT (BAD DEBT)', value: 'AUTO TOP-UP SETTLEMENT CASH PAYMENT (BAD DEBT)'},
    {label: 'BPLRT - ADMIN CHARGE (EXCESS TIME/LOST CARD/UPGRADE)', value: 'BPLRT - ADMIN CHARGE (EXCESS TIME/LOST CARD/UPGRADE)'},
    {label: 'BPLRT - TRAIN SVC DISRUPTION', value: 'BPLRT - TRAIN SVC DISRUPTION'},
    //{label: 'BPLRT TUK - CARD IS NOT UPDATED (PAYMENT)', value: 'BPLRT TUK - CARD IS NOT UPDATED (PAYMENT)'},
    //{label: 'BPLRT TUK - CARD IS NOT UPDATED (WAIVED)', value: 'BPLRT TUK - CARD IS NOT UPDATED (WAIVED)'},
    {label: 'CASH FOUND / RETRIEVED BY AFC TECH', value: 'CASH FOUND / RETRIEVED BY AFC TECH'},
    {label: 'CEPAS CORRUPTED AFTER SOLD (REFUND)', value: 'CEPAS CORRUPTED AFTER SOLD (REFUND)'},
    {label: 'CLAIMED DID NOT/ USED WRONG CARD TO TRAVEL', value: 'CLAIMED DID NOT/ USED WRONG CARD TO TRAVEL'},
    {label: 'COMPLIMENTARY TICKET (BDD / TRAIN RIDE VOUCHER)', value: 'COMPLIMENTARY TICKET (BDD / TRAIN RIDE VOUCHER)'},
    {label: 'DECIDED NOT TO TRAVEL/TRAIN OVER CROWDED/LIFT OUT OF SVC', value: 'DECIDED NOT TO TRAVEL/TRAIN OVER CROWDED/LIFT OUT OF SVC'},
    {label: 'DEFFERED REFUND - (RAISE IR)', value: 'DEFFERED REFUND - (RAISE IR)'},
    {label: 'EXCESS CASH AT EOS', value: 'EXCESS CASH AT EOS'},
    {label: 'EXCESS CASH DURING CARD SALES / TOP UP', value: 'EXCESS CASH DURING CARD SALES / TOP UP'},
    {label: 'EXCESS TIME (REFUND)', value: 'EXCESS TIME (REFUND)'},
    {label: 'EPAYMENT TERMINAL', value: 'EPAYMENT TERMINAL'},
    {label: 'EXIT AT WRONG STN / CHECKED VALUE AT GATE', value: 'EXIT AT WRONG STN / CHECKED VALUE AT GATE'},
    {label: 'FOUND BACK LOST CARD/ PAID EXIT (REFUND)', value: 'FOUND BACK LOST CARD/ PAID EXIT (REFUND)'},
    {label: 'FOUND CASH AND HANDED OVER TO PSC', value: 'FOUND CASH AND HANDED OVER TO PSC'},
    //{label: 'FOUND CASH AT GTM', value: 'FOUND CASH AT GTM'},
    {label: 'GATE FAULT', value: 'GATE FAULT'},
    //{label: 'GTM NO CHANGE MODE / EXACT FARE', value: 'GTM NO CHANGE MODE / EXACT FARE'},
    //{label: 'GTM SHORT CHANGED', value: 'GTM SHORT CHANGED'},
    //{label: 'GTM VALUE NOT ADDED - PAYMENT', value: 'GTM VALUE NOT ADDED - PAYMENT'},
    //{label: 'GTM / PSM VALUE NOT ADDED - WAIVED', value: 'GTM / PSM VALUE NOT ADDED - WAIVED'},
    {label: 'MANUAL REFUND OF CEPAS CARD / RECEIPT AT TSO', value: 'MANUAL REFUND OF CEPAS CARD / RECEIPT AT TSO'},
    {label: 'MISMATCH', value: 'MISMATCH'},
    {label: 'MISSED LAST TRAIN', value: 'MISSED LAST TRAIN'},
    {label: 'NEL TRAIN DISRUPTION, BUS REFUND', value: 'NEL TRAIN DISRUPTION, BUS REFUND'},
    {label: 'PARTIAL PYMT FOR EXCESS TIME/ LOST CARD/ FREE EXIT (AMT RECEIPT)', value: 'PARTIAL PYMT FOR EXCESS TIME/ LOST CARD/ FREE EXIT (AMT RECEIPT)'},
    {label: 'PAX ADD CASH TO ROUND UP CARD VALUE', value: 'PAX ADD CASH TO ROUND UP CARD VALUE'},
    {label: 'PAX CLAIMED BUS/ TRAIN REBATE/ EARLY BIRD WAIVE', value: 'PAX CLAIMED BUS/ TRAIN REBATE/ EARLY BIRD WAIVE'},
    {label: 'PAX RECLAIMED EXCESS CASH LEFT AT PSC', value: 'PAX RECLAIMED EXCESS CASH LEFT AT PSC'},
    {label: 'PCC CLAIMED', value: 'PCC CLAIMED'},
    {label: 'PCC CLAIMED - BPLRT', value: 'PCC CLAIMED - BPLRT'},
    {label: 'PURCHASE OF WRONG CONC PASS (REFUND)', value: 'PURCHASE OF WRONG CONC PASS (REFUND)'},
    {label: 'REFUND LAST ADD VALUE', value: 'REFUND LAST ADD VALUE'},
    {label: 'REFUSED TO PAY DUE', value: 'REFUSED TO PAY DUE'},
    {label: 'REMOVAL FOR ILLEGALLY PARKED BICYCLE', value: 'REMOVAL FOR ILLEGALLY PARKED BICYCLE'},
    {label: 'REPLACEMENT OF CORRUPTED NEW CEPAS AFT SOLD (AMT WAIVED)', value: 'REPLACEMENT OF CORRUPTED NEW CEPAS AFT SOLD (AMT WAIVED)'},
    {label: 'SHORTAGE OF CASH AT EOS (RAISE IR)', value: 'SHORTAGE OF CASH AT EOS (RAISE IR)'},
    {label: 'STAFF PAYMENT', value: 'STAFF PAYMENT'},
    {label: 'TRAIN SVC DISRUPTION', value: 'TRAIN SVC DISRUPTION'},
    {label: 'TUK - CARD IS NOT UPDATED (PAYMENT)', value: 'TUK - CARD IS NOT UPDATED (PAYMENT)'},
    {label: 'TUK - CARD IS NOT UPDATED (WAIVED)', value: 'TUK - CARD IS NOT UPDATED (WAIVED)'},
    {label: 'TUK - CASH FOUND AT TUK', value: 'TUK - CASH FOUND AT TUK'},
    {label: 'UNCONFIRMED ADD VALUE (AMT RECEIPT)', value: 'UNCONFIRMED ADD VALUE (AMT RECEIPT)'},
    {label: 'UNDER FARE / MANUAL REFUND OF CEPAS (AMT RECEIPT)', value: 'UNDER FARE / MANUAL REFUND OF CEPAS (AMT RECEIPT)'},
  ]
}
