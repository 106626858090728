import axios from "axios";
import get from 'lodash.get'
import { sanitizeUrl } from '@braintree/sanitize-url'

import store from "@/store";

// interceptor
axios.interceptors.request.use(function(config) {
  config.url = sanitizeUrl(config.url);
  return config;
})

export default (secured = true) => {
  if (secured) {
    return axios.create({
      headers: { "Authorization": `bearer ${get(store, 'state.auth.token')}` }
    });
  } else {
    return axios.create();
  }
} 