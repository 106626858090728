import {
  GET_NOTIFICATIONS,
  MARK_READ_NOTIFICATION,
  NOTIFICATION_HEALTH_CHECK,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { errorHandler, successHandler } from "./serviceHandlers";

export async function notificationsHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(
      NOTIFICATION_HEALTH_CHECK
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getNotificationList(skip = 0, take = 100) {
  try {
    const resp = await createAxiosInstance(true).get(GET_NOTIFICATIONS, {
      params: {
        skip: skip,
        take: take,
      },
    });

    return successHandler(resp);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function markReadNotification() {
  try {
    const resp = await createAxiosInstance(true).post(MARK_READ_NOTIFICATION);

    return successHandler(resp);
  } catch (e) {
    return errorHandler(e);
  }
}
