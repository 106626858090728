import {
  ACKNOWLEDGEMENT_DETAILS,
  LIST_PERSONAL_ACKNOWLEDGEMENT,
  ACKNOWLEDGEMENT_SUMMARY,
  ACKNOWLEDGE_CP_Update,
  CP_UPDATE_DETAILS,
  LIST_CP_UPDATES,
  CREATE_CP_UPDATE,
  EDIT_CP_UPDATE,
  REMOVE_CP_UPDATE,
  CP_UPDATE_SUMMARY,
  OPS_COMM_HEALTH_CHECK,
  USER_ACKNOWLEDGEMENT_SUMMARY,
  OPS_COMM_CONFIGURATION_DETAILS,
  OPS_COMM_UPDATE_THRESHOLD_EXPIRE,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { getURLSearchParams } from "./service.helpers";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function opsCommHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(OPS_COMM_HEALTH_CHECK);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getOpsCommConfigurationDetails(lineId) {
  try {
    const response = await createAxiosInstance(
      true
    ).get(OPS_COMM_CONFIGURATION_DETAILS, { params: { lineId } });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function opsCommUpdateThresholdExpiration(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      OPS_COMM_UPDATE_THRESHOLD_EXPIRE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getAcknowledgementDetails({ id, cPUpdateId }) {
  try {
    const params = getURLSearchParams({
      data: {
        id,
        cPUpdateId,
      },
    });
    const response = await createAxiosInstance(
      true
    ).get(ACKNOWLEDGEMENT_DETAILS, { params: params });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function listPersonalAcknowledgement({
  isAcknowledged,
  isExpired,
  title,
  includePastThreeMonthsData,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        isAcknowledged,
        isExpired,
        title,
        includePastThreeMonthsData,
      },
    });
    const response = await createAxiosInstance(
      true
    ).get(LIST_PERSONAL_ACKNOWLEDGEMENT, { params: params });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getAcknowledgementSummary(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      ACKNOWLEDGEMENT_SUMMARY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getUserAcknowledgementSummary(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      USER_ACKNOWLEDGEMENT_SUMMARY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function acknowledgeCPUpdate(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      ACKNOWLEDGE_CP_Update,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCPUpdateDetails({ id }) {
  try {
    const params = getURLSearchParams({
      data: {
        id,
      },
    });
    const response = await createAxiosInstance(true).get(CP_UPDATE_DETAILS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getCPUpdateSummary() {
  try {
    const response = await createAxiosInstance(true).get(CP_UPDATE_SUMMARY);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfCPUpdates() {
  try {
    const response = await createAxiosInstance(true).get(LIST_CP_UPDATES);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createCPUpdate(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_CP_UPDATE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editCPUpdate(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_CP_UPDATE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeCPUpdate(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      REMOVE_CP_UPDATE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
