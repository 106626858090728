import get from "lodash.get";
import { listStationTransactions } from "../../services/cashManagement.service";

const state = () => ({
  list: [],
  isLoading: false,
  error: null,
});

const getters = {
  pscTransactions: (state) => state.list.filter(item => item.isPSC),
  afcTransactions: (state) => state.list.filter(item => item.isAFC),
  transactionsOfTypes: (state) => (natureOfDiscrepancyEnum) => 
    state.list.filter(item => item.natureOfDiscrepancyEnum == natureOfDiscrepancyEnum),
}

const mutations = {
  setList: (state, newList = []) => {
    state.list = [ ...newList ];
  },
  setIsLoading: (state, isLoading = false) => {
    state.isLoading = isLoading;
  },
  setError: (state, error = null) => {
    state.error = error;
  },
  reset: (state) => {
    state.list = [];
    state.isLoading = false;
    state.error = null;
  }
}

const actions = {
  loadData: async function({ commit, rootGetters }) {
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];
    const lineId = rootGetters['auth/userLineId'];

    commit('setIsLoading', true);
    const result = await listStationTransactions({ stationId, lineId });
    if (result.success) {
      commit('setList', get(result, 'payload.offlineTransactions.transactions', []));
      commit('setError', null);
      return result;
    } else {
      commit('setError', result.payload);
      return result;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}