import ROLES from "../../Roles.json";

export default [
  {
    path: "/inventory",
    component: () => import("../views/inventory/InventoryDefaultWrapper.vue"),
    meta: {
      title: "screens.inventory.Main.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Inventory Main Page",
        component: () => import("../views/inventory/InventoryMainPage.vue"),
        meta: {
          title: "screens.inventory.Main.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.OpsAdmin,
            ROLES.FSOManager,
            ROLES.FSOAdministrativeOfficer,
          ],
        },
      },
      {
        path: "expired-items",
        name: "Expired Inventory List",
        component: () => import("../views/inventory/ExpiredInventories.vue"),
        meta: {
          title: "screens.inventory.ExpiredInventories.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.OpsAdmin,
            ROLES.FSOManager,
            ROLES.FSOAdministrativeOfficer,
          ],
        },
      },
      {
        path: "general",
        name: "General Inventory",
        component: () =>
          import("../views/inventory/InventoryTypes/General/Index.vue"),
        meta: {
          title: "screens.inventory.InventoryTypes.General.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "smart-card",
        name: "Smart Card Inventory",
        component: () =>
          import("../views/inventory/InventoryTypes/SmartCard/Index.vue"),
        meta: {
          title: "screens.inventory.InventoryTypes.SmartCard.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager
          ],
        },
      },
      {
        path: "tracked-items",
        name: "Tracked Items Inventory",
        component: () =>
          import("../views/inventory/InventoryTypes/TrackedItems/Index.vue"),
        meta: {
          title: "screens.inventory.InventoryTypes.TrackedItems.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "first-aid",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.inventory.InventoryTypes.FirstAid.pageTitle",
        },
        children: [
          {
            path: "",
            name: "First Aid",
            component: () =>
              import("../views/inventory/InventoryTypes/FirstAid/Index.vue"),
            meta: {
              title: "screens.inventory.InventoryTypes.FirstAid.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.OpsAdmin,
                ROLES.ServiceOperationManager,
                ROLES.FSOManager,
                ROLES.FSOAdministrativeOfficer,
              ],
            },
          },
          {
            path: "incident",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.inventory.InventoryTypes.FirstAid.formList.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Injury Incidents",
                component: () =>
                  import("../views/inventory/InventoryTypes/FirstAid/FormList.vue"),
                meta: {
                  title: "screens.inventory.InventoryTypes.FirstAid.formList.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                  ],
                },
              },
            ]
          },
          {
            path: "use-items",
            name: "Use First Aid Items",
            component: () =>
              import("../views/inventory/InventoryTypes/FirstAid/UseItems.vue"),
            meta: {
              title: "screens.inventory.InventoryTypes.FirstAid.Use.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "view/:firstAidBoxId",
            name: "View First Aid Box",
            component: () =>
              import(
                "../views/inventory/InventoryTypes/FirstAid/ViewFirstAidBox.vue"
              ),
            meta: {
              title: "screens.inventory.InventoryTypes.FirstAid.view.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.OpsAdmin,
                ROLES.ServiceOperationManager,
              ],
            },
          },
          {
            path: "view-log/:inventoryTransactionId",
            name: "View First Aid Log",
            component: () =>
              import(
                "../views/inventory/InventoryTypes/FirstAid/ViewFirstAidLog.vue"
              ),
            meta: {
              title:
                "screens.inventory.InventoryTypes.FirstAid.viewLog.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.OpsAdmin,
              ],
            },
          },
        ],
      },
      {
        path: "order",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.inventory.Order.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Order Main Page",
            component: () => import("../views/inventory/Order/Index.vue"),
            meta: {
              title: "screens.inventory.Order.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager
              ],
            },
          },
          {
            path: "create",
            name: "Create Order",
            component: () => import("../views/inventory/Order/CreateOrder.vue"),
            meta: {
              title: "screens.inventory.Order.createOrder.pageTitle",
              requiresRoles: [
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "move-in",
            name: "Move In Items",
            component: () =>
              import("../views/inventory/Order/MoveIn/MoveInItems.vue"),
            meta: {
              title: "screens.inventory.Order.moveInItems.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "view/:inventoryOrderId",
            name: "View Order",
            component: () => import("../views/inventory/Order/ViewOrder.vue"),
            meta: {
              title: "screens.inventory.Order.viewOrder.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
              ],
            },
          },
        ],
      },
      {
        path: "configuration",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.inventory.Configuration.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Configuration Main Page",
            component: () =>
              import("../views/inventory/Configuration/Index.vue"),
            meta: {
              title: "screens.inventory.Configuration.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.OpsAdmin,
                ROLES.FSOManager,
                ROLES.FSOAdministrativeOfficer,
              ],
            },
          },
          {
            path: "item-type",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.inventory.Configuration.itemType.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Item Type Configuration Main Page",
                component: () =>
                  import("../views/inventory/Configuration/ItemType/Index.vue"),
                meta: {
                  title: "screens.inventory.Configuration.itemType.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.OpsAdmin,
                    ROLES.FSOManager,
                    ROLES.FSOAdministrativeOfficer,
                  ],
                },
              },
              {
                path: "create",
                name: "Create Item Type",
                component: () =>
                  import(
                    "../views/inventory/Configuration/ItemType/CreateItemType.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.itemType.create.pageTitle",
                  requiresRoles: [
                    ROLES.ServiceOperationManager,
                    ROLES.OpsAdmin,
                  ],
                },
              },
              {
                path: "update/:inventoryTypeId",
                name: "Update Item Type",
                component: () =>
                  import(
                    "../views/inventory/Configuration/ItemType/UpdateItemType.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.itemType.update.pageTitle",
                  requiresRoles: [
                    ROLES.ServiceOperationManager,
                    ROLES.OpsAdmin,
                  ],
                },
              },
            ],
          },
          {
            path: "item-type-threshold",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title:
                "screens.inventory.Configuration.itemTypeThreshold.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Item Type Threshold Configuration Main Page",
                component: () =>
                  import(
                    "../views/inventory/Configuration/InventoryThreshold/Index.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.itemTypeThreshold.pageTitle",
                  requiresRoles: [ROLES.OpsAdmin],
                },
              },
              {
                path: "create",
                name: "Create Item Type Threshold",
                component: () =>
                  import(
                    "../views/inventory/Configuration/InventoryThreshold/create.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.itemTypeThreshold.create.pageTitle",
                  requiresRoles: [ROLES.OpsAdmin],
                },
              },
              {
                path: "view/:inventoryTypeThresholdId",
                name: "View Item Type Threshold",
                component: () =>
                  import(
                    "../views/inventory/Configuration/InventoryThreshold/ViewItemTypeThreshold.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.itemTypeThreshold.view.pageTitle",
                  requiresRoles: [ROLES.OpsAdmin],
                },
              },
            ],
          },
          {
            path: "first-aid-box",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.inventory.Configuration.firstAidBox.pageTitle",
            },
            children: [
              {
                path: "",
                name: "First Aid Box Configuration Main Page",
                component: () =>
                  import(
                    "../views/inventory/Configuration/FirstAidBox/Index.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.firstAidBox.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.OpsAdmin
                  ],
                },
              },
              {
                path: "create",
                name: "Create First Aid Box",
                component: () =>
                  import(
                    "../views/inventory/Configuration/FirstAidBox/CreateFirstAidBox.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.firstAidBox.create.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.OpsAdmin
                  ],
                },
              },
              {
                path: "update/:inventoryFirstAidBoxId",
                name: "Update First Aid Box",
                component: () =>
                  import(
                    "../views/inventory/Configuration/FirstAidBox/UpdateFirstAidBox.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.firstAidBox.update.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.OpsAdmin
                  ],
                },
              },
            ],
          },
          {
            path: "location",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.inventory.Configuration.location.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Location Configuration Main Page",
                component: () =>
                  import("../views/inventory/Configuration/Location/Index.vue"),
                meta: {
                  title: "screens.inventory.Configuration.location.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.OpsAdmin,
                    ROLES.FSOManager,
                    ROLES.FSOAdministrativeOfficer,
                  ],
                },
              },
              {
                path: "create",
                name: "Create Location",
                component: () =>
                  import(
                    "../views/inventory/Configuration/Location/CreateLocation.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.location.create.pageTitle",
                  requiresRoles: [
                    ROLES.ServiceOperationManager,
                    ROLES.OpsAdmin,
                  ],
                },
              },
              {
                path: "update/:inventoryLocationId",
                name: "Update Location",
                component: () =>
                  import(
                    "../views/inventory/Configuration/Location/UpdateLocation.vue"
                  ),
                meta: {
                  title:
                    "screens.inventory.Configuration.location.update.pageTitle",
                  requiresRoles: [
                    ROLES.ServiceOperationManager,
                    ROLES.OpsAdmin,
                  ],
                },
              },
            ],
          },
          {
            path: "notification",
            name: "Notification Configuration Main Page",
            component: () =>
              import("../views/inventory/Configuration/Notification/Index.vue"),
            meta: {
              title: "screens.inventory.Configuration.notification.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
              ],
            },
          },
        ],
      },
      {
        path: "view-items",
        name: "View Items Main Page",
        component: () => import("../views/inventory/ViewItems/Index.vue"),
        meta: {
          title: "screens.inventory.ViewItems.pageTitle",
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
      {
        path: "reports",
        name: "Reports Main Page",
        component: () => import("../views/inventory/Reports/Index.vue"),
        meta: {
          title: "screens.inventory.Reports.pageTitle",
          requiresRoles: [
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
          ],
        },
      },
    ],
  },
];
